import { NextSeo } from 'next-seo';

import AssembleLayout from '@/src/layouts/AssembleLayout';
import MinimalLayout from '@/src/layouts/MinimalLayout';
import NotFoundCover from '@/src/views/root/NotFoundCover';

export default function FourOFourPage() {
  return (
    <AssembleLayout Layout={MinimalLayout} Component={NotFoundCover}>
      <NextSeo title="Página 404" />
    </AssembleLayout>
  );
}
