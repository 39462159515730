import Link from 'next/link';

import { Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Image } from '@/src/components/atoms';

const useStyles = makeStyles(theme => ({
  toolbar: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainer: {
    width: 147,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 168,
      height: 32,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
}));

const Topbar = ({ themeMode, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Toolbar className={clsx(classes.toolbar, className)} {...rest}>
      <div className={classes.logoContainer}>
        <Link href="/">
          <a title="Eskolare">
            <Image
              className={classes.logoImage}
              src={
                themeMode === 'light' ? '/logo/horizontal-color.svg' : '/logo/horizontal-white.svg'
              }
              alt="Eskolare"
              lazy={false}
            />
          </a>
        </Link>
      </div>
    </Toolbar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  themeMode: PropTypes.string.isRequired,
};

export default Topbar;
